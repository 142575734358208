<template>
  <v-dialog :value="visible" persistent fullscreen>
    <v-card class="payment-modal grey lighten-4" outlined>
      <v-container style="max-width: 1200px; padding: 0">
        <div class="d-flex align-center justify-space-between header">
          <div class="payment-title">
            {{ $t('order_modal.payment_modal.title_many') }}
          </div>
          <v-icon @click="close" x-large>mdi-close</v-icon>
        </div>
        <div class="payment-card-items d-flex">
          <div class="first-block">
            <div class="payment-filter mb-2">
              <PaymentTypeItem
                v-for="(payment, index) in payments.slice(0,2)"
                :key="'payment-' + index + payment.id"
                :payment="payment"
                :disabled="remnant <= 0 || (payment.is_single && singlePayments.includes(payment.id))"
                @click="addPayment"
              />
              <div class="payment">
                <v-card
                  flat
                  :color="payments.length <= 2 ? 'rgb(208, 208, 208)' : '#BBC4C8' "
                  @click="openPayments"
                  :disabled="payments.length <= 2"
                >
                  <v-card-text class="card-payment-text d-flex align-center" style="color: black">
                    <MoreSvg class="icon-more-svg" />
                    <div class="payment-name ">{{ $t('stop_list.reasons.other') }}</div>
                  </v-card-text>
                </v-card>
              </div>
            </div>
            <v-card flat class="payment-card-item-list">
              <v-card-text class="py-5 px-3" >
                <div class="d-flex justify-space-between mb-3" style="color:#111111">
                  <div style="font-size: 18px;">{{ $t('order_amount') }}:</div>
                  <div style="font-size: 28px; font-weight: 500;">{{ $n(order.amount, 'currency') }}</div>
                </div>
                <div class="selected-payment">
                  <SelectPaymentTypeItem
                    v-for="(payment, index) in selectTypes"
                    :key="'select-payment-' + index + payment.id"
                    :payment="payment"
                    :remnant="remnant"
                    :change="change"
                    :is-selected="keyboard.index === index"
                    :is-consider="parseInt(payment.amount) !== 0"
                    @open-keyboard="openKeyboard(index)"
                    @update-amount="updateAmount($event, index)"
                    @delete="deletePayment(index)"
                  />
                </div>
              </v-card-text>
            </v-card>
            <v-card flat style="grid-row: 2;">
              <v-card-text class="bottom-order-info black--text d-flex flex-column justify-space-between">
                <div class="d-flex justify-space-between">
                  <div class="sum-title">Внесено</div>
                  <div class="sum-currency">{{ $n(madenPaymentSum, 'currency') }}</div>
                </div>
                <div class="d-flex justify-space-between font-weight-bold">
                  <div class="sum-title">Внести</div>
                  <div class="sum-currency">{{ $n(remnant, 'currency') }}</div>
                </div>
                <div class="d-flex justify-space-between font-weight-bold">
                  <div class="sum-title">{{ $t('money.change.self') }}</div>
                  <div class="sum-currency">{{ $n(change, 'currency') }}</div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="second-block">
            <v-card flat>
              <NumberKeyboard
                ref="keyboard"
                :index="keyboard.index"
                :payment-name="keyboard.name"
                :amount="keyboard.amount"
                :edit-change="keyboard.editChange"
                :current-max-value="currentMaxValue"
                :ignore-max-value="!!keyboard.is_cash"
                @input-amount="inputAmount"
              >
                <template #exact-amount>
                  <v-btn depressed
                         @click="exactAmount(keyboard.index)"
                         class="keyboard-action-btn mb-2 d-flex"
                         color="#546E7A"
                         dark>
                    <v-icon size="32" class="me-3">mdi-refresh</v-icon>
                    <div style="font-size: 16px;">Точная сумма</div>
                  </v-btn>
                </template>
                <template #save>
                  <v-btn
                    :disabled="confirmProcess || !selectTypes || !!remnant || (!change && paymentsMoreOrderAmount)"
                    depressed
                    class="keyboard-action-btn"
                    color="#FFD600"
                    @click="changeHandler"
                  >
                    <div style="word-break: normal; white-space: normal">
                      {{ $t(isIikoMode ? 'order_modal.btn.send_to_iiko' : 'save') }}
                    </div>
                  </v-btn>
                </template>
              </NumberKeyboard>
            </v-card>
          </div>
        </div>
      </v-container>
    </v-card>
    <v-dialog v-model="otherPayments.show" width="500">
      <v-card>
        <v-card-text>
          <div class="d-flex flex-column">
            <v-btn
              color="#CFD8DC"
              class="my-2"
              v-for="(payment, index) in payments"
              :key="index"
              @click="addPayment(payment)"
            >
              {{ payment.name }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import SelectPaymentTypeItem from '../Payment/SelectPaymentTypeItem.vue'
import PaymentTypeItem from '../Payment/PaymentTypeItem.vue'
import NumberKeyboard from '@/components/NumberKeyboard.vue'
import MoreSvg from '@/assets/icons/more.svg'

export default {
  name: 'PaymentTypeModal',
  components: { NumberKeyboard, PaymentTypeItem, SelectPaymentTypeItem, MoreSvg },
  props: {
    visible: Boolean,
    order: Object,
    list: Array,
    mode: String
  },
  data: () => ({
    otherPayments: {
      show: false
    },
    selectTypes: [],
    width: '60%',
    keyboard: {
      is_cash: 0,
      index: -1,
      amount: 0,
      name: '',
      editChange: false
    },
    confirmProcess: false
  }),
  computed: {
    currentMaxValue () {
      return this.order.subtotal - this.amountWithoutCurrentType
    },
    amountWithoutCurrentType () {
      return this.selectTypes.reduce((out, payment, currentIndex) => {
        if (currentIndex === this.keyboard.index) {
          return out
        }
        out += payment.amount
        return out
      }, 0)
    },
    paymentsMoreOrderAmount () {
      return this.madenPaymentSum > this.order.subtotal
    },
    isIikoMode () {
      return this.mode === 'iiko'
    },
    madenPaymentSum () {
      return this.selectTypes
        .filter((payment) => !payment.is_discount)
        // eslint-disable-next-line no-return-assign
        .reduce((out, payment) => out += parseFloat(payment.amount), 0)
    },
    remnant () {
      let remnant = this.order.amount
      this.selectTypes
        .filter((payment) => !payment.is_discount)
        .map(payment => {
          remnant -= payment.amount
        })
      return (!remnant || isNaN(remnant)) ? 0 : parseFloat(remnant)
    },
    singlePayments () {
      return this.selectTypes.reduce((out, item) => {
        if (item.is_single) {
          out.push(item.order_payment_id)
        }
        return out
      }, [])
    },
    payments () {
      return this.list.reduce((out, item) => {
        out.push({
          ...item
        })
        return out
      }, [])
    },
    amountCashChange () {
      return this.selectTypes
        .filter(el => el.is_cash)
        .reduce((total, item) => total + parseFloat(item.amount_change), 0)
    },
    amountCash () {
      return this.selectTypes
        .filter(el => el.is_cash)
        // eslint-disable-next-line no-return-assign
        .reduce((total, item) => total += parseFloat(item.amount), 0)
    },
    amountNotCash () {
      return this.selectTypes
        .filter(el => !el.is_cash)
        // eslint-disable-next-line no-return-assign
        .reduce((total, item) => total += parseFloat(item.amount), 0)
    },
    remnantCash () {
      const value = this.order.subtotal - this.order.discount
      return (!value || isNaN(value)) ? 0 : value
    },
    change () {
      const change = this.amountCashChange - this.amountCash
      return change > 0 ? change : 0
    }
  },
  methods: {
    changeHandler () {
      this.confirmProcess = true
      // let change = this.change
      const selectTypes = this.selectTypes
        .filter(el => parseInt(el.amount) !== 0)
        .map(pType => {
          const newType = { ...pType }
          // eslint-disable-next-line camelcase
          const { is_cash, amount } = newType
          // eslint-disable-next-line camelcase
          if (is_cash) {
            newType.amount_change = this.change + amount
          }
          return newType
        })

      this.$emit((this.isIikoMode ? 'send-to-iiko' : 'change'), selectTypes, this.change)
      this.confirmProcess = false
    },
    openPayments () {
      this.otherPayments.show = true
    },
    close () {
      this.$emit('close')
    },
    addPayment (payment) {
      const amount = this.remnant

      if (payment.is_cash) {
        payment.amount_change = this.remnant
      }

      if (amount <= 0) return

      this.selectTypes.push({
        ...payment,
        id: 0,
        amount_change: isNaN(payment.amount_change) ? 0 : payment.amount_change,
        order_id: this.order.id,
        order_payment_id: payment.id,
        status_payment: 0,
        amount
      })
      this.openKeyboard(this.selectTypes.length - 1)
    },
    deletePayment (index) {
      this.selectTypes.splice(index, 1)
      this.resetKeyboard()
    },

    resetKeyboard () {
      this.keyboard = {
        is_cash: 0,
        index: -1,
        amount: 0,
        name: '',
        editChange: false
      }
    },

    openKeyboard (index) {
      // eslint-disable-next-line camelcase
      const { is_cash = 0, amount_change, amount, name, status_payment } = this.selectTypes[index]
      // eslint-disable-next-line camelcase
      if (status_payment > 0) return
      this.keyboard.index = index
      // eslint-disable-next-line camelcase
      this.keyboard.is_cash = is_cash
      // eslint-disable-next-line camelcase
      this.keyboard.amount = is_cash ? amount_change : amount
      this.keyboard.name = name
      this.keyboard.editChange = false
    },

    inputAmount ({ value, index = -1 }) {
      if (index === -1) return
      let val = value ? value.toString().replace(',', '.') : '0'
      val = parseFloat(val).toFixed(2)
      // eslint-disable-next-line camelcase
      const { is_cash } = this.selectTypes[index]
      // eslint-disable-next-line camelcase
      if (is_cash) {
        const remnantIsNotCorrect = this.remnantCash < 0
        const amount = this.remnantCash - parseFloat(val) > 0 ? val : (remnantIsNotCorrect ? 0 : this.remnantCash)
        this.selectTypes[index].amount_change = remnantIsNotCorrect ? 0 : parseFloat(val)
        this.selectTypes[index].amount = amount
      } else {
        this.selectTypes[index].amount = parseFloat(val)
      }

      // eslint-disable-next-line camelcase
      const { amount_change, amount } = this.selectTypes[index]

      // eslint-disable-next-line camelcase
      this.keyboard.amount = parseFloat((is_cash ? amount_change : amount).toFixed(2))
      this.$nextTick(() => {
        this.keyboard.editChange = false
      })
    },
    updateAmount (val, index = -1) {
      this.keyboard.index = index
      this.inputAmount({ value: parseFloat(this.remnant) + parseFloat(val), index })
    },
    exactAmount (index) {
      const { amount = 0 } = this.selectTypes[index]
      this.keyboard.editChange = true
      this.inputAmount({ value: parseFloat(this.remnant) + parseFloat(amount), index })
    },
    changeKeyboard () {
      this.selectTypes = JSON.parse(JSON.stringify(this.order.payment_types))
      this.$nextTick(() => {
        if (this.selectTypes.length) {
          this.openKeyboard(0)
        }
      })
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.changeKeyboard()
      } else {
        this.resetKeyboard()
      }
    }
  },
  created () {
    this.changeKeyboard()
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Inter", sans-serif;
  src: url("../../assets/fonts/Inter-VariableFont_slnt,wght.ttf");
}
.keyboard-action-btn{
  padding: 32px !important;
  max-height: 68px !important;
}
.bottom-order-info{
  height: 100%;
  padding: 18.5px 16px;
  .sum-title{
    font-size: 16px;
  }
  .sum-currency{
    font-size: 18px;
  }
}
.payment-name{
  font-size: 16px;
  margin-left: 5px;
}
.icon-more-svg{
  fill: black;
}
.payment-filter{
  display: flex;
  justify-content: space-between;
  .payment{
    flex: 2;
    &:last-child{
      flex: 1;
    }
    .v-card{
      height: 100%;
    }
  }
}
.payment-card-item-list{
  height: 100%;
  margin-bottom: 10px;
  border-radius: 8px !important;
}
.second-block{
  display: flex;
  .v-card{
    border-radius: 8px !important;
  }
}
.first-block{
  display: flex;
  flex-flow: column;
  //gap: 10px;
  width: 100%;
  justify-content: space-between;
  .v-card{
    border-radius: 8px !important;
  }
}
.payment-card-items {
  display: grid;
  grid-template-columns: 680px 510px;
  grid-template-rows: 1fr 130px;
  grid-gap: 10px;
  justify-content: center;
  &>.v-card {
    border-radius: 8px;
  }
/*  @media only screen and (max-width: 1030px) {
    grid-template-columns: 535px 445px;
    grid-template-rows: 1fr 126px;
    grid-gap: 7px;
  }*/
}
.selected-payment {
  > div:only-child {
    border-radius: 8px !important;
  }

  > div:last-child {
    border-radius: 2px 2px 4px 4px;
  }

  > div:first-child {
    border-radius: 4px 4px 2px 2px;
  }
}
.v-dialog--fullscreen {
  .payment-modal {
    font-family: "Inter Thin", sans-serif;
    //font-feature-settings: "ss21" on;
    border: none;
    padding: 5px 20px !important;

    &.grey.lighten-4{
      background: #E3E3E3 !important;
      border-color: #E3E3E3 !important;
    }
  }
}
.payment-title {
  width: 100%;
  font-size: 20px;
  padding: 15px 0;
  .title-amount {
    color: #fff;
    padding: 5px;
    border-radius: 10px;
  }
}

.footer {
  height: 10vh;
  display: grid;
  grid-template-columns: 65% 35%;
  font-size: 18px;

  .footer-amount-box {
    display: flex;
    justify-content: space-between;

    .footer-amount {
      color: #fff;
      padding: 5px;
      border-radius: 4px;
    }
  }

  .footer-action-box {
    display: flex;
    justify-content: end;

    .footer-btn {
      color: #fff;
    }
  }
}

.left-container {
  width: 65%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.right-container {
  width: 35%;
  padding: 8px 0;
  text-align: center;
  overflow: auto;
}

.remnant {
  display: flex;
  justify-content: end;
  padding: .5rem 1rem;
  font-size: 18px;
}

.list {
  .payment {
    display: grid;
    margin: 4px 0 4px 0;

    button {
      overflow: hidden;
    }
  }
}

.payment-row {
  .payment-chips {
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem;

    .v-chip {
      min-width: 100px;
    }

    .paid-status {
      color: #fff;
      justify-content: center;
    }
  }
}
</style>
